<template>
  <div class="modal fade" :id="(!this.isEmpty(this.modal_id)) ? this.modal_id : 'changeObjectPrice'" tabindex="-1" aria-labelledby="Изменить цену и комиссию" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content p-20">
        <div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
          <span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Изменить цену и комиссию</span>
          <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body d-flex flex-column p-0">
          <div class="row">
            <div class="col-8">
              <InputText inputId="prices_amount" inputName="prices_amount" ref="prices.amount"
                         inputLabel="Цена:" inputLabelClass="text-font-secondary mb-3" inputClass="rounded-3 p-2"
                         inputWrapClass="mb-20"
                         :inputValue="(!this.isEmpty(this.item.prices)) ? this.item.prices.amount : 0"
                         :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                         inputMode="numeric" inputStep="10000" inputMin="0" inputMax="2147000000"
                         inputIsPrice="true" inputRequired="true"
                         @setValue="(value) => this.setAndValidate('prices.amount', value)" />
            </div>
            <div class="col-4">
              <InputSelect inputId="prices_currency" inputLabel="Валюта:"
                           v-if="this.item.prices"
                           inputLabelClass="text-font-secondary mb-3" inputName="prices_currency"
                           ref="prices.currency" :inputOptions="this.currency_list"
                           :inputValue="(this.item.prices) ? this.item.prices.currency : 0"
                           inputClass="rounded-1 mb-10" :inputUsePlaceholder="false"
                           inputWrapClass="flex-column mb-3"
                           @setValue="(value) => this.setAndValidate('prices.currency', value)" />
            </div>
          </div>
          <InputText v-if="this.item.prices?.currency != 0" inputId="prices_price_avito"
                     inputName="prices_price_avito" ref="prices.price_avito" inputLabel="Цена в рублях:"
                     inputLabelClass="text-font-secondary mb-3" inputClass="rounded-3 p-2" inputWrapClass="mb-20"
                     :inputValue="(!this.isEmpty(this.item.prices)) ? this.item.prices.price_avito : 0"
                     :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                     inputMode="numeric" inputStep="10000" inputMin="0" inputMax="2147000000" inputIsPrice="true"
                     inputRequired="true" @setValue="(value) => this.setAndValidate('prices.price_avito', value)" />
          <div class="row">
            <div class="col-8">
              <InputNumber
                        inputId="commission_agency" input-name="commission_agency" ref="commission_agency"
                        inputLabel="Наша комиссия:" inputLabelClass="text-font-secondary mb-3" inputClass="rounded-3 p-2"
                        inputWrapClass="mb-20" :inputValue="(this.item.commission_agency) ? this.item.commission_agency : 0"
                        inputMin="0" inputMax="2147000000" inputStep="0.5"
                        @setValue="(value) => this.setAndValidate('commission_agency', value)" />
            </div>
            <div class="col-4">
              <InputSelect inputId="commission_agency_type" inputLabelClass="text-font-secondary mb-3"
                           inputLabel="Тип: " inputName="commission_agency_type"
                           ref="commission_agency_type" :inputOptions="this.commission_agency_type_list"
                           :inputValue="(this.item.commission_agency_type) ? this.item.commission_agency_type : 0"
                           inputClass="rounded-1 mb-10" :inputUsePlaceholder="false"
                           inputWrapClass="flex-column mb-3"
                           @setValue="(value) => this.setAndValidate('commission_agency_type', value)" />
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 p-0">
          <button
              type="button"
              class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
              @click="this.editObjectPrice()">
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import api from "@/api";
import CommonService from "@/services/CommonService";
import InputText from "@/components/inputs/InputText";
import InputNumber from "@/components/inputs/InputNumber";
import InputSelect from "../inputs/InputSelect.vue";

export default {
  name: "ChangeObjectPrice",
  props: {
    modalId: { type: String },
    contractSection: { type: String },
    sourceId: { type: [Number, String] },
    simulateAdd: { type: [Boolean] },
  },
  data() {
    return {
      modal_id: (typeof this.modalId !== "undefined") ? this.modalId : null,
      contract_id: (typeof this.contractId !== "undefined") ? this.contractId : null,
      section: (typeof this.contractSection !== "undefined") ? this.contractSection : null,
      source_id: (typeof this.sourceId !== "undefined") ? parseInt(this.sourceId) : null,
      resultsModalState: {
        state: '',
        title: '',
        message: ''
      },
      item: {
        operation_type: 2,
        object_type: 1,
        lease_type: null,
        commercial_type: null,
        bind_req_client: [],
        address: {
          country_id: null,
          region_id: null,
          full: null,
          kladr_id: null,
        },
        geo: {
          lat: null,
          lng: null,
        },
        title: null,
        prices: {
          amount: null,
          currency: 0,
          price_avito: 0,
          is_commission: null,
          commission: null,
          commission_agent: null,
          amount_commission: null,
        },
        commission_agency: null,
        commission_agency_type: null,
        deal_type: null,
        tax_number: null,
        floors: {
          num: null,
          max: null,
        },
        areas: {
          full: null,
          kitchen: null,
          live: null,
          land: null,
        },
        metro: {
          type: null,
        },
        owners: [],
        newbuildings: {
          residential_id: null,
          end_date: null,
        },
        rooms_count: null,
        rooms_count_all: null,
        avito_room_type_id: null,
        house_type: null,
        house_material_type: null,
        house_category_id: null,
        build_year: null,
        photos: [],
        plans: [],
        parts_area: [],
        fields: [],
        variables: [],
        add_watermark: false,
        use_in_advert: true,
        is_free_plan: false,
        renovation_id: null
      },
      simulate: (typeof this.$props.simulateAdd !== "undefined") ? (this.$props.simulateAdd) : false,
      contract_types: [],
      errors: [],
    }
  },
  methods: {
    isEmpty(data) {
      return CommonService.isEmpty(data);
    },
    inArray(needle, haystack) {
      return CommonService.inArray(needle, haystack);
    },
    setAndValidate(name, value, skip_advert_check) {
      let errors = [];
      let parent = null;
      let child = null;
      let parts = name.split('.', 2);
      if (parts.length == 2) {
        parent = parts[0];
        child = parts[1];
      }

      skip_advert_check = false;
      if (typeof skip_advert_check != "undefined")
        skip_advert_check = true;

      // Валидация значения
      if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
        /*if ('invalid' in this.$refs[name])
          this.$refs[name].invalid = false;*/

        if (this.$refs[name].inputLabel) {
          let label = this.$refs[name].inputLabel.replace(/:+$/, '');

          let is_skip = false;
          /*if (name == 'funnel_id' && value == 0)
            is_skip = true;*/

          if (this.$refs[name].required && !is_skip) {
            if (value) {
              if (this.$refs[name].required && value.toString() == '0')
                errors.push('Поле `' + label + '` обязательно к заполнению!');
              else if (this.$refs[name].required && this.isEmpty(value))
                errors.push('Поле `' + label + '` обязательно к заполнению!');
            } else {
              errors.push('Поле `' + label + '` обязательно к заполнению!');
            }
          }

          if (!this.isEmpty(errors)) {

            this.errors[name] = {
              name: name,
              label: label,
              errors: errors
            };

            this.$refs[name].valid = false;
            this.$refs[name].invalid = errors.join('<br/>');
          } else {
            this.$refs[name].valid = true;
            this.$refs[name].invalid = false;
          }
        }
      }

      // Проверки перед установкой значения
      if (name) {
        if (this.inArray(name, ['prices.amount', 'prices.amount_commission', 'prices.prep', 'prices.start', 'prices.fact', 'prices.price_avito', 'commission_agency'])) {
          if (typeof value === "string" && !this.isEmpty(value)) {
            value = parseInt(value.replace(/\s/g, ''));
          }
        }
      }

      // Установка значения
      if (parent && child) {
        if (!this.isEmpty(this.item[parent])) {
          if (typeof (this.item[parent][child]) !== "undefined") {
            this.item[parent][child] = value;
          } else {
            this.item[parent] = {
              ...{
                [child]: value
              },
              ...this.item[parent]
            };
          }
        } else {
          this.item[parent] = {
            [child]: value
          };
        }
      } else if (parent == null && name) {
        /*if (name == 'fields') {
          return;
        } else {
          this.item[name] = value;
        }*/
        this.item[name] = value;
      } else {
        if (parent && child)
          this.item[parent][child] = null;
        else
          this.item[name] = null;
      }

      this.is_form_changed = true;

      CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });



      if (!this.isEmpty(errors))
        return false;

      return true;
    },
    validateAll(fields) {

      this.errors = [];
      let is_valid = false;
      let collection = CommonService.proxyToObject(fields);
      let invalid_input = null;
      let blocks = [
        'prices',
      ];

      for (const [name, value] of Object.entries(collection)) {

        let input_is_valid = true;
        if (typeof name !== "undefined") {
          if (name == 'fields' && !this.isEmpty(this.custom_fields_list)) {
            input_is_valid = this.$refs.customFields?.validateAll();
          } else if (this.inArray(name, blocks)) {
            blocks.forEach((block_name) => {
              if (name == block_name) {

                let entries = [];
                if (!this.isEmpty(value))
                  entries = Object.entries(value);

                for (const [field_name, field_value] of entries) {
                  if (!this.setAndValidate(block_name + '.' + field_name, field_value, true)) {
                    input_is_valid = false;
                  }
                }
              }
            });
          } else {
            if (!this.setAndValidate(name, value, true)) {
              input_is_valid = false;
            }
          }
        }

        console.log('Validate: ' + name + ', is valid? - ' , input_is_valid);
      }

      if (typeof Object.values(this.errors) == "object") {
        this.errors = CommonService.removeEmpty(Object.values(this.errors));
        if (typeof (this.errors[0]) !== "undefined") {
          if (!this.isEmpty(Object.values(this.errors)[0]['name'])) {
            let invalid_input = Object.values(this.errors)[0]['name'];
            if (invalid_input && typeof invalid_input !== "undefined") {
              let input = this.$refs[invalid_input].$el;
              const inputes = Object.values(this.errors).map(error => this.$refs[error.name].$el)
              if (input) {
                this.$nextTick(() => CommonService.scrollIntoMaxTopView(inputes, 60));
              }
            }
          }
        }
      }

      is_valid = this.isEmpty(this.errors);

      CommonService.log('debug', 'validateAll()', { fields: collection, is_valid: is_valid, errors: this.errors });

      return is_valid;
    },
    getObject() {

      this.emitter.emit("global.startGetObject", { ref: 'AddObject::getObject()', object_id: this.object_id });

      return api.get('/object', {
          params: {
            object_id: this.object_id,
          }
        })
        .then((response) => {

          CommonService.log('debug', 'getObject()::axios', { response: response.data });

          this.emitter.emit("global.endGetObject", { response: response });

          if (response.status == 200) {

            if (response.data.user_can_edit == false)
              this.user_cant_edit = true;

            if (response.data.success && !this.isEmpty(response.data.item))
              return response.data.item;

          }
          return null;
        }).catch(function (error) {

          CommonService.log('error', 'getObject()::axios', error);
          this.emitter.emit("global.endGetObject", { error: error });

        });
    },
    editObjectPrice: function () {
      CommonService.log('debug', 'editObjectPrice()', this.item);

      if (this && this.validateAll(this.item)) {
        this.is_form_changed = false;
        this.is_form_processed = true;

        api.post('/object/change-price', this.item, {
            xdebug: true
          })
          .then((response) => {
            if (process.env.NODE_ENV == "development")
              console.debug('editObjectPrice()::axios', {
                response: response.data
              });

            if (response.status == 200 && response.data.success) {

              this.$store.commit('resetFormsState', 'changeObjectPrice');
              this.resultsModalState.state = 'success';

              if (this.item.id)
                this.resultsModalState.title = 'Цена объекта была успешно изменена!';

              if (!this.isEmpty(response.data.object_id))
                this.item.id = response.data.object_id;

            } else {
              this.resultsModalState.state = 'error';

              if (this.item.id)
                this.resultsModalState.title = 'Ошибка изменения цены объекта.';

              if (!this.isEmpty(response.data.errors))
                this.resultsModalState.message = response.data.errors.join('<br/>');

            }

            this.is_form_processed = false;

            this.emitter.emit('updateObjectPrice', this.item.id);

            this.$emit('resultsModalStateChanged', this.resultsModalState)

            let modal = CommonService.getModal('changeObjectPrice');
            modal.hide()
          })
          .catch(function (error) {
            CommonService.log('error', 'editObjectPrice()::axios', error);
            // this.is_form_processed = false;
          });
      }
    },
  },
  components: {
    InputSelect,
    InputText,
    InputNumber,
  },
  async mounted() {
    let modal = document.getElementById('changeObjectPrice');
    if (modal && typeof modal !== "undefined") {

      let _this = this;
      modal.addEventListener('hidden.bs.modal', function (event) {
        _this.section = null;
        _this.contract_id = null;
        _this.source_id = null;
        _this.item = {
          prices: {
            currency: 0
          }
        };
        _this.emitter.emit("global.modalClose", modal);
        _this.$emit('modalClosed');
        // _this.resetData();
      });

      modal.addEventListener('shown.bs.modal', async function (event) {
        let target = event.relatedTarget
        if (typeof target !== "undefined") {

          let section = target.getAttribute('data-bs-section');
          if (typeof section !== "undefined") {
            _this.section = section;
          }

          let object_id = target.getAttribute('data-bs-object-id');
          if (typeof object_id !== "undefined") {
            _this.object_id = object_id;
          }

          let object = target.getAttribute('data-bs-object');
          if (typeof object !== "undefined") {
            _this.object = JSON.parse(object);
          }

          let source_id = target.getAttribute('data-bs-source-id');
          if (typeof source_id !== "undefined") {
            _this.source_id = source_id;
          }

          let simulate = target.getAttribute('data-bs-simulate');
          if (typeof simulate !== "undefined") {
            _this.simulate = simulate;
          }

          if (_this.section && _this.contract_id) {
            _this.getContract(_this.section, _this.contract_id).then(data => { _this.item = data });
          }
        }

        if (process.env.NODE_ENV == "development") {
          console.debug('addEditContractModal::show.bs.modal', {
            simulate: _this.simulate,
            section: _this.section,
            contract_id: _this.contract_id,
            source_id: _this.source_id
          });
        }
        _this.emitter.emit("global.modalShown", modal);
        _this.$emit('modalShowed');

        if (_this.object && _this.object.id)
        {
          _this.item = {..._this.item, ..._this.object};
          if (_this.item.prices.currency) {
            switch (_this.item.prices.currency) {
              case 'USD':
                _this.item.prices.currency = 1
                break;
              case 'EUR':
                _this.item.prices.currency = 2
                break;
              case 'RUB':
              case 'RUR':
                _this.item.prices.currency = 0
                break;
            }
          }
        }
        else
        {
          await _this.getObject().then(data => {
            _this.item = {..._this.item, ...data};
            if (_this.item.prices.currency) {
              switch (_this.item.prices.currency) {
                case 'USD':
                  _this.item.prices.currency = 1
                  break;
                case 'EUR':
                  _this.item.prices.currency = 2
                  break;
                case 'RUB':
                case 'RUR':
                  _this.item.prices.currency = 0
                  break;
              }
            }
          })
        }
      });
    }
  },
  computed: {
    currency_list() {
      return [
        { value: 0, name: "₽" },
        { value: 1, name: "$" },
        { value: 2, name: "€" },
      ]
    },
    commission_agency_type_list() {
      return [
        { value: 'price', name: "Сумма" },
        { value: 'percent', name: "%" },
      ]
    },
  },
}
</script>